import React from 'react';

function AboutUs() {
  return (
    <div>
      <h1>Welcome to our Company</h1>
      <p>This is the About Us page of our company website.</p>
    </div>
  );
}

export default AboutUs;