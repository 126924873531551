// Technology.js
import React from 'react';

function Technology() {
  return (
    <div>
      <h1>Our Technology</h1>
      <p>Information about the technology used in our company.</p>
      <h1>Coming Soon</h1>
    </div>
  );
}

export default Technology;
