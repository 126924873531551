import React, { useState } from 'react';
import Modal from 'react-modal';
import './Career.css';  // Import the CSS file

// Import images
import LetterofRecommendation from '../img/Letter of Recommendation.png';
import FlexibleWorkingHours from '../img/flexible-working-hours.png';  
import HandsOnExperience from '../img/hands-on-experience.png';  

// If you have local copies of these icons, import them similarly:
// import DiplomaIcon from '../img/diploma.png'; 

// Or use an external URL (test this URL in a browser)
const DiplomaIcon = 'https://img.icons8.com/ios-filled/50/000000/diploma.png';

Modal.setAppElement('#root');

const jobListings = [
  {
    title: 'Web Developer Intern',
    company: 'Zeldatech Innovations Pvt. Ltd.',
    mode: 'Work from home',
    qualification: 'B.Tech (Any year)/Having relevant skills',
    description: 'The candidate should be well-versed in the React.js framework and possess a strong foundation in HTML, CSS, and JavaScript, along with familiarity in developing the front end of websites.',
    applicationFormLink: 'https://forms.gle/8gm4CP2TMmXqRyAm8',
  },
  {
    title: 'UI/UX Intern',
    company: 'Zeldatech Innovations Pvt. Ltd.',
    mode: 'Work from home',
    qualification: 'B.Tech (Any year)/Having relevant skills',
    description: 'The candidate should be well-versed in UI/UX design principles and possess a strong foundation in design tools such as Adobe XD, Figma, or Sketch. Familiarity with user research, wireframing, prototyping, and user testing. Understanding of ReactJS and JavaScript is a plus.',
    applicationFormLink: 'https://forms.gle/8gm4CP2TMmXqRyAm8',
  },
  {
    title: 'Data Analysis Intern',
    company: 'Zeldatech Innovations Pvt. Ltd.',
    mode: 'Work from home',
    qualification: 'B.Tech /MBA (Any year)/Having relevant skills',
    description: 'The candidate should be well-versed in data analysis techniques and possess a strong foundation in tools such as Excel, SQL, and Python. Familiarity with data visualization tools like Tableau or Power BI. Understanding of statistical analysis and data modeling.',
    applicationFormLink: 'https://forms.gle/8gm4CP2TMmXqRyAm8',
  },
  {
    title: 'Mechanical Design Intern',
    company: 'Zeldatech Innovations Pvt. Ltd.',
    mode: 'Work from home',
    qualification: 'B.Tech MECHANICAL (Any Year)',
    description: 'The candidate should be proficient in CATIA and AutoCAD with a good knowledge of machine design.',
    applicationFormLink: 'https://forms.gle/8gm4CP2TMmXqRyAm8',
  },
  {
    title: 'HR Intern',
    company: 'Zeldatech Innovations Pvt. Ltd.',
    mode: 'Kolhapur (Hybrid Mode 2 DAYS ONLINE 3 DAYS OFFLINE)',
    qualification: 'BBA/MBA (Any Year)',
    description: 'The candidate should be well-versed in HR principles and possess a strong foundation in recruitment, employee engagement, and HR operations.',
    applicationFormLink: 'https://forms.gle/8gm4CP2TMmXqRyAm8',
  },
  // Add applicationFormLink for other job listings as needed
];

const perks = [
  {
    icon: DiplomaIcon,
    text: 'Internship Completion Letter',
  },
  {
    icon: LetterofRecommendation,
    text: 'Letter of Recommendation',
  },
  {
    icon: FlexibleWorkingHours,
    text: 'Flexible Working Hours',
  },
  {
    icon: HandsOnExperience,
    text: 'Hands-on Experience',
  },
];

function Career() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    position: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const openModal = (position) => {
    const job = jobListings.find((job) => job.title === position);
    if (job && job.applicationFormLink) {
      window.open(job.applicationFormLink, '_blank');
    } else {
      // Handle case where no application form link is available
      console.error("No application form link available for this position");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className='heading'>
        <h1>Careers at Zeldatech</h1>
      </div>
      <div className="company-details">
        <h2>About Zeldatech Innovations Pvt. Ltd.</h2>
        <p>Zeldatech Innovations is a dynamic startup dedicated to pushing the boundaries of technology and delivering innovative solutions to our clients. As a growing company, we offer a unique opportunity to make a significant impact and grow alongside us.</p>
      </div>

      <div className="why-join-us">
        <h2>Why Join Us?</h2>
        <p>At Zeldatech, we foster a culture of innovation, collaboration, and continuous learning. Being part of our startup means you'll have the chance to work in a fast-paced environment where your ideas matter and you can make a real difference. Join us to be part of a team that is passionate about shaping the future of technology.</p>
      </div>

      <div className='job-listing-container'>
        <h2 style={{ textAlign: 'center' }}>Current Hiring</h2>
        <div className="job-listings">
          {jobListings.map((job, index) => (
            <div key={index} className="job-card">
              <h2>{job.title}</h2>
              <p><strong>Company:</strong> {job.company}</p>
              <p><strong>Mode:</strong> {job.mode}</p>
              <p><strong>Qualification:</strong> {job.qualification}</p>
              <p>{job.description}</p>
              <button onClick={() => openModal(job.title)}>Apply Now</button>
            </div>
          ))}
        </div>
      </div>

      <div className="perks">
        <h2>Perks</h2>
        <div className="perk-list">
          {perks.map((perk, index) => (
            <div key={index} className="perk-item">
              <img src={perk.icon} alt={perk.text} />
              <p>{perk.text}</p>
            </div>
          ))}
        </div>
      </div>

      
    </div>
  );
}

export default Career;




