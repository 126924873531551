import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../App.css'; // Import the external CSS file

function Navbar() {
  const [isOpen, setIsOpen] = useState(false); // State to manage menu visibility
  const location = useLocation(); // Get the current location

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle menu visibility
  };

  const getPageClass = (pageName) => {
    // Check if the current pathname matches the pageName
    return location.pathname === `/${pageName}` ? 'active-page' : '';
  };

  return (
    <nav className="navbar">
      <div className="menu-icon" onClick={toggleMenu}>&#9776;</div>
      <ul className={isOpen ? "nav-links open" : "nav-links"}>
        <li className={`navbar-item1 ${getPageClass('')}`}><Link to="/" className="navbar-link">Home</Link></li>
        <li className={`navbar-item2 ${getPageClass('aboutus')}`}><Link to="/aboutus" className="navbar-link">About Us</Link></li>
        
        <li className={`navbar-item4 ${getPageClass('team')}`}><Link to="/team" className="navbar-link">Team</Link></li>
        <li className={`navbar-item5 ${getPageClass('career')}`}><Link to="/career" className="navbar-link">Career</Link></li>
        <li className={`navbar-item6 ${getPageClass('contact')}`}><Link to="/contact" className="navbar-link">Contact</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;
