import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import '../App.css'; // Import the external CSS file
import image1 from '../img/image1.svg'; // Import your images
import image2 from '../img/erox_1.svg'; // Import your images
import background from '../img/building.png'; // Import your images
import aniket from '../img/aniket.png';
import arjun from '../img/arjun.png';
import tej from '../img/tej.png';

function Home() {
  return (
    <div>
      {/* Section 1 */}
      <section className="section1" style={{ backgroundImage: `url(${background})` }}>
        <h4 className='toptext'>Projecting Technology of Future</h4>
      </section>

      {/* Section 2 */}
      <section className="section2">
        <div className="two-column">
          <div className="column">
            <img className='logo_aboutus' src={image1} alt="About Us" />
          </div>
          <div className="column">
            <h2 className='heading'>About Us</h2>
            <p className='textright' >We are a software and semi-hardware solutions company that provides innovative and high-end solutions for real-world and societal problems. Our team is dedicated to creating cutting-edge technology that can transform businesses and industries.</p>
            <Link to="/aboutus" className="button1">Know more</Link>
          </div>
        </div>
      </section>

      {/* Section 3 */}
      <section className="section3">
        <div className="two-column">
          <div className="column">
            <h2 className='heading'>Technology</h2>
            <p className='text'>The EROX Printing Network revolutionizes printing experiences by seamlessly connecting users to automated booths through a centralized internet platform. Swift Printing Setup streamlines print setups directly with hardware, minimizing stall times for users. Effortless Interaction is facilitated through an automated system and client-server platform, enhancing user-facility interaction. Real-Time Updates provide instant information on nearby booths' distance and stall times, ensuring efficient printing processes. Together, these features create a dynamic and user-friendly printing environment.</p>
            <Link to="/technology" className="button">Know more</Link>
          </div>
          <div className="column">
            <img src={image2} alt="Technology" />
          </div>
        </div>
      </section>

      {/* Section 4 */}
      <section className="section4">
        <h2 className='heading1'>Team</h2>
        <div className="three-column">
          <div className="column">
            <img className='photo4' src={aniket} alt="Team Member" />
            <a href="https://www.linkedin.com/in/aniket-walake"><FaLinkedin className="icon1" /></a>
            <h5 className='name'>Aniket Walake</h5>
            <h6 className='post'>Co-founder & CEO</h6>
          </div>
          <div className="column">
            <img className='photo4' src={arjun} alt="Team Member" />
            <a href="http://linkedin.com/in/arjun-khedekar-3091171b8"><FaLinkedin className="icon1" /></a>
            <h5 className='name'>Arjun Khedekar</h5>
            <h6 className='post'>Co-founder & CTO</h6>
          </div>
          <div className="column">
            <img className='photo4' src={tej} alt="Team Member" />
            <a href="http://linkedin.com/in/tej-shiralkar-35178816b"><FaLinkedin className="icon1" /></a>
            <h5 className='name'>Tej Shiralkar</h5>
            <h6 className='post'>Co-founder & COO</h6>
          </div>
        </div>
      </section>

      {/* Section 5 */}
      <section className="section5">
        <div className="three-column">
          <div className="column">
            <h3><FontAwesomeIcon icon={faMapMarkerAlt} /> Address</h3>
            <p>H NO 517, NR RADHAKRISHNA MANDIR,Kagal,Kagal,Kolhapur-416216,Maharashtra</p>
          </div>
          <div className="column">
            <h3><FontAwesomeIcon icon={faPhone} /> Contact Number</h3>
            <p>8999080707</p>
          </div>
          <div className="column">
            <h3><FontAwesomeIcon icon={faEnvelope} /> Email</h3>
            <p href="info.zeldatech@gmail.com">info.zeldatech@gmail.com</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
