import React from 'react';
import Navbar from './Navbar';
import logo from '../img/logo_white.png'; // Import the logo image
import '../App.css'; // Import the external CSS file

function Header() {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <Navbar />
    </header>
  );
}

export default Header;
