// Team.js
import React from 'react';
import aniket from '../img/aniket.png';
import arjun from '../img/arjun.png';
import tej from '../img/tej.png';
import { FaLinkedin } from 'react-icons/fa';


function Team() {
  return (
    <section className="section4">
        <h2 className='heading1'>Team</h2>
        <div className="three-column">
          <div className="column">
            <img className='photo4' src={aniket} alt="Team Member" />
            <a href="https://www.linkedin.com/in/aniket-walake"><FaLinkedin className="icon1" /></a>
            <h5 className='name'>Aniket Walake</h5>
            <h6 className='post'>Co-founder & CEO</h6>
          </div>
          <div className="column">
            <img className='photo4' src={arjun} alt="Team Member" />
            <a href="http://linkedin.com/in/arjun-khedekar-3091171b8"><FaLinkedin className="icon1" /></a>
            <h5 className='name'>Arjun Khedekar</h5>
            <h6 className='post'>Co-founder & CTO</h6>
          </div>
          <div className="column">
            <img className='photo4' src={tej} alt="Team Member" />
            <a href="http://linkedin.com/in/tej-shiralkar-35178816b"><FaLinkedin className="icon1" /></a>
            <h5 className='name'>Tej Shiralkar</h5>
            <h6 className='post'>Co-founder & COO</h6>
          </div>
        </div>
      </section>
  );
}

export default Team;
