// Footer.js
import React from 'react';
import { FaLinkedin, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../img/logo_white.svg';
import '../App.css'; // Import the external CSS file

function Footer() {
  return (
    <footer className="footer">
      <div className="left-column">
        <div className="social-media">
          <a href="https://www.linkedin.com/company/zeldatech-innovations-pvt-ltd/"><FaLinkedin className="icon" /></a>
          <a href="https://www.instagram.com/zeldatechinnovations/"><FaInstagram className="icon" /></a>
        </div>
        <div className="page-links">
        <Link to="/" >Home</Link>
        <Link to="/aboutus" >About Us</Link>
        <Link to="/technology" >Technology</Link>
        <Link to="/team">Team</Link>
        <Link to="/career" >Career</Link>
        <Link to="/contact" >Contact</Link>
        </div>
      </div>
      <div className="right-column">
        <img src={logo} alt="Logo" className="logo" />
      </div>
    </footer>
  );
}

export default Footer;
